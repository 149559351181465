@keyframes flicker {
    0% {
        background-color: rgba(@error-color, 0.2);
    }
    50% {
        background-color: rgba(@error-color, 0.4);
    }
    100% {
        background-color: rgba(@error-color, 0.2);
    }
}

.elements {
    cursor: pointer;
    height: 100%;
    width: 100%;
    background: transparent;
    transition: all 0.33s ease;
    border-radius: 5px;
    position: relative;
    font-size: 1.3rem;
    font-weight: 700;
    overflow: auto;
    // &__message {
    //     position: absolute;
    //     right: 0;
    //     top: -9px;
    //     color: @error-color;
    //     font-size: 18px;
    // }

    // &__icon {
    //     opacity: 0;
    //     text-transform: uppercase;
    // }

    &:not(.__input, .__textarea):hover {
        span {
            opacity: 1;
        }
    }

    &.__choice {
        .anticon {
            font-size: 1.2rem;
        }
        &.--circle {
            border-radius: 50%;
            border-width: 3px;
        }
        &.--underline {
            background-color: transparent;
            border-width: 0px 0px 3px 0px;
        }
        &.--selected {
            border: 3px solid @warning-bold;

            &.--correct {
                border-color: @success-color;
            }
            &.--incorrect {
                border-color: @error-color;
            }
        }

        &.--tc,
        &.--tick {
            border: 0;
            span,
            svg {
                height: 100%;
                width: 100%;
                display: block;
            }
            &.--correct {
                background-color: rgba(@success-color, 0.2);
            }
            &.--incorrect {
                background-color: rgba(@error-color, 0.2);
            }
        }
    }

    &.__matching {
        // background-color: rgba(@primary-light-3, 0.2);
        &.--pending {
            color: @sub-color;
            font-weight: 600;
            animation: flicker 2s infinite;
            border: 2px solid @primary-light-3;
            span {
                opacity: 1;
            }
        }

        &.--matched {
            background-color: transparent;
            cursor: auto;
            &.--correct {
                background-color: rgba(@success-color, 0.2);
            }
            &.--incorrect {
                background-color: rgba(@error-color, 0.2);
            }
        }
    }

    &.__input {
        color: @dark;
        text-align: left;
        padding: 0;
        background-color: rgba(@info-color, 0.1);
        &::placeholder {
            color: @dark;
        }
        &.--correct {
            color: @success-color;
            background-color: rgba(@success-color, 0.1);
            &::placeholder {
                color: @success-color;
            }
        }
        &.--incorrect {
            color: @error-color;
            background-color: rgba(@error-color, 0.1);
            &::placeholder {
                color: @error-color;
            }
        }
    }

    &.__textarea {
        background-color: @white;
        border: 2px solid @info-color;
        color: @dark;
        height: 100%;
        &::placeholder {
            color: @dark;
        }
    }

    &__limit-character {
        position: absolute;
        right: 0;
        top: -22px;
    }

    &.__drop {
        // height: 100%;
        padding: 0.5rem;
        align-content: center;
        position: relative;
        .drag_item {
            width: 100%;
            border-radius: 5px;
            &:hover {
                .remove_item {
                    opacity: 1;
                }
            }
        }
        .remove_item {
            position: absolute;
            opacity: 0;
            transition: all 0.33s ease;
        }
        &:hover {
            background-color: transparent;
        }
    }
    &.__drag {
        -webkit-user-drag: element;
        user-select: none;
        position: relative;
        text-align: center;
        border: 0;
        cursor: -webkit-grabbing;
        cursor: grabbing;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transition: all 0.33s ease;
        transform: scale(1);
        &:hover {
            transform: scale(1.05);
            background-size: 100% 100%;
            background-color: transparent;
        }
    }

    &.__voice {
        position: absolute;
        height: 80px;
        width: 100%;
        top: -70px;
        left: 0;
        background-color: @white;
        padding: 1rem;
    }

    .voice-container {
        width: 100%;
        &:only-child {
            .voice-control {
                margin-left: auto;
            }
        }
        #waveform {
            border-radius: 12px;
            background-color: @primary-light-2;
            padding: 0px 0.5rem;
        }
        .audio_player {
            width: 60%;
        }
    }
}
