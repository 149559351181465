.cb__login {
    height: 100vh;
    background-color: @white;
    text-align: center;
    padding: 1rem;

    .breakpoint-pc({
        padding: 40px;
    });

    &__content {
        position: relative;
        height: 100%;
        > div {
            height: 100%;
            img {
                max-height: 98%;
                width: auto;
            }
        }
        .ant-form {
            text-align: center;
            width: clamp(350px, 100%, 500px);
            margin-top: 1rem;
            .breakpoint-pc({
                margin-top: 2rem;
            });
            label {
                font-weight: 600;
            }
            .ant-input-prefix {
                margin-right: 15px;
                margin-left: 6px;
            }

            .ant-btn[type="submit"] {
                width: 100%;
                font-size: 18px;
            }
        }

        .ant-btn {
            font-weight: 800;
            color: @dark;
            width: 100%;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
        .email_title {
            color: @primary-color;
            margin: 40px 0;
        }
        .welcome_title {
            width: 100%;
            display: block;
        }
        .hotline {
            position: absolute;
            bottom: 0px;
            gap: 0.5rem;
        }
    }

    &__header {
        z-index: 9;
        width: 100%;
        position: absolute;
        left: 50px;
        top: 0px;
        .ant-segmented {
            position: fixed;
            left: 50%;
            transform: translate(-50%);
            width: max-content;
            top: 100px;
            .breakpoint-sm({
                top: 1rem;
            });
        }
        img {
            height: 48px;
            width: auto;
        }
    }
}

.email_send_success {
    max-width: 100dvw;
    height: 100dvh;
    top: 0;
    padding: 0;
    border-radius: 0;
    .ant-modal-content {
        height: 100%;
        border-radius: 0px;
        gap: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-typography {
            max-width: 40vw;
            text-align: center;
        }
        .ant-modal-body {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
        }
    }
}
