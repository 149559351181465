#p_activate {
    background: rgba(@primary, 0.05);
    height: 100vh;
    padding-top: 70px;
    .ant-tabs {
        width: 100%;
        margin-bottom: 0;
        .ant-tabs-content {
            min-height: 50vh;
            max-height: 75vh;
            overflow-y: scroll;
        }
        .ant-tabs-tabpane {
            height: 50vh
        }
    }

    .activate-forme {
        height: 100%;
        gap: 1rem;
        text-align: center;
        .ant-input {
            width: 100%;
            text-align: center;
            height: 50px;
            letter-spacing: 1px;
            font-size: 1.6rem;
            text-transform: uppercase;
            color: @primary;
            font-weight: 600;
            .breakpoint-tablet({
                width: 500px;
            })
        }
    }
}
