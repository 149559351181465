.loading_container {
    position: relative;
    min-height: 200px;
}
.app_loading {
    position: absolute;
    height: 100%;
    width: 100%;
    background: @primary-light-1;
    top: 0;
    left: 0;
    transition: all 0.33s ease;
    z-index: 18;
}

.page {
    background-color: @white;
    border-radius: 12px;
    padding: 12px;
    overflow: auto;
    max-height: calc(100dvh - 80px);
    height: 100%;
    > .ant-row, > .ant-col {
        height: 100%;
    }
    .breakpoint-pc({
        max-height: calc(100dvh - 104px);
    });
    .breakpoint-sm({
        padding: 24px;
        border-radius: 32px;
    });
}

.text {
    margin-bottom: 0;
    margin: 0.3rem 0;
    &--center {
        margin-top: 0.3rem !important;
        text-align: center;
        width: 100%;
    }
}

img,
picture,
video {
    width: 100%;
    height: auto;
}

img {
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: middle;
    font-style: italic;
    shape-margin: 1rem;
}

audio::-internal-media-controls-download-button {
    display: none;
}
audio::-webkit-media-controls-enclosure {
    overflow: hidden;
}
audio::-webkit-media-controls-panel {
    width: calc(100% + 33px);
}

.nodata {
    text-align: center;
    width: 100%;
    margin: 1rem auto;
    img {
        width: 150px;
    }
}

.modal_report {
    .ant-modal-body {
        max-height: calc(100vh - 100px);
        overflow: auto;
    }
}

.app_sidebar {
    height: 100dvh;
    background-color: @white;
    border-right: 2px solid @light-1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .breakpoint-sm({
        position: relative;
    });

    .ant-layout-sider-children {
        position: relative;
        padding: 32px 16px;
        .ant-btn {
            width: 100%;
            border-radius: 12px;
            border-width: 2px;
            color: @dark-1;
            font-size: 1.125rem;
            line-height: 1;
            justify-content: start;
            height: 68px;
            font-weight: 700;
            margin-bottom: 16px;
            color: @dark-1;
            .anticon {
                margin-right: 0.5rem;
            }
            &:hover,
            &.active {
                background-color: @primary-light-1;
            }
            &.active {
                border-color: @primary;
                color: @dark;
                font-weight: 700;
            }
            span {
                text-align: left;
            }
        }
    }
    .ant-layout-sider-trigger {
        height: 52px;
        width: 52px !important;
        position: absolute;
        right: -70px;
        top: 14px;
        background-color: @white;
        color: @dark;
        border-radius: 12px;
        border: 1px solid @dark-3;
        border-width: 1px 1px 4px 1px;
        .breakpoint-sm({
            right: 22px;
            bottom: 32px;
            top: auto;
        });
    }
    .app_sidebar__logo {
        width: 100%; /* Makes the image fill the width of its parent */
        height: auto; /* Keeps the image's aspect ratio */
        display: block; /* Removes the gap below the image (common with inline images) */
        max-width: 100%; /* Ensures the image doesn’t overflow its parent */
    }
    &.ant-layout-sider-collapsed {
        text-align: center;
        width: 100px;
        min-width: 100px !important;
        left: -100px;
        .breakpoint-sm({
            left: 0px;
        });
        .app_sidebar__label {
            display: none;
        }
        .app_sidebar__logo {
            height: 32px;
            width: 32px;
            margin: 0 auto;
        }
        button span:not(.anticon) {
            display: none;
        }
    }
}

.ant-layout-content {
    background-color: @white;
}

// Notifications
.notifications {
    height: 70dvh;
    overflow: auto;
    .noti {
        padding: 16px;
        background-color: @white;
        width: 100%;
        border-bottom: 1.5px solid @light-1;
        transition: all 0.33s;
        border-radius: 12px;
        &.__unread,
        &:hover {
            background-color: @primary-light-1;
        }
        img {
            width: 30px;
            height: 30px;
        }
        &__fromnow {
            font-size: 0.9rem;
        }
    }
}

.rpv-core__text-layer {
    display: none;
}

.rpv-core__page-layer {
    h4 {
        font-family: serif;
    }
}