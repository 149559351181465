.give_assignment {
    &__content {
        &__allinfo, &__topic {
            padding: 24px;
            border-radius: 20px;
            border: 1px solid @dark-3;
        }

        .ant-input-textarea-show-count {
            position: relative;
            &::after {
                position: absolute;
                right: 12px;
                bottom: 8px;
                font-size: 14px;
                color: @dark-1;
            }
        }
        h5.ant-typography {
            font-size: 16px;
        }
        .select_time {
            height: 50px;

            > .other_time {
                width: 200px;
                margin-left: 30px;
                transition: all 0.33s ease;
                opacity: 0;
                z-index: -1;
                &.--open {
                    opacity: 1;
                    margin-left: 50px;
                    z-index: 1;
                }
            }
        }

        .select_class {
            .card {
                white-space: nowrap;
                padding: 12px 16px;
                
            }
        }
        &__topic {
            border: 1px solid @dark-3;
            border-radius: 20px;
            padding: 16px;
            .card {
                padding: 75px 0px;
                border-width: 2px 2px 4px 2px;
                .ant-typography {
                    font-weight: 700;
                    font-size: 20px;

                    .anticon {
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }

        .baseinfo {
            display: grid;
            grid-template-columns: 100%;
            gap: 1rem;
            .ant-picker {
                width: 100%;
            }

            .breakpoint-lg({
                grid-template-columns: 40% 28% 28%;
                gap: 2%;
            })
        }
    }
}
.selected_book_preview {
    border: 1px solid @dark-3;
    border-radius: 12px;
    padding: 16px;
    img {
        border-radius: 8px;
    }
}

// manage asisngment
.manage_assignment {
    .card {
        height: 100%;
        .ant-typography {
            line-height: 1.7;
        }
    }
    .ant-tag-has-color {
        color: @error-color;
    }

    .student_completed {
        margin-top: 1rem;
        text-align: center;
        .large {
            font-size: 32px;
            font-weight: 800;
        }
        .small {
            font-size: 18px;
            color: @dark-1;
        }
    }
    .ant-progress {
        .ant-progress-outer {
            width: calc(100% - 20px);
        }
        .ant-progress-text {
            float: left;
            margin-left: 0;
            margin-right: 20px;
            font-weight: 700;
            color: @success-color;
            line-height: 30px;
        }
        .ant-progress-inner {
            background-color: #e6f9ec;
        }
    }
    &__filter {
        margin-bottom: 1rem;
        margin-top: 1rem;
        .ant-input-affix-wrapper {
            width: 41%;
        }
        .ant-btn-primary.btn-white {
            float: right;
        }
    }

    .chart-container canvas {
        height: 120px !important;
        width: 100% !important;
    }
}

.student_detail {
    .ant-segmented {
        width: 100%;
        .ant-segmented-item {
            width: 50%;
        }
    }
    .student_radar {
        border: 1px solid @dark-3;
        padding: 20px;
        border-radius: 20px;
        text-align: center;
        .ant-btn {
            width: 100%;
        }
    }
}

.selected_assignment {
    height: calc(65dvh - 140px);
    .ant-col {
        height: 100%;
         overflow: auto;
    }
    .ant-tree {
        height: 100%;
         overflow: auto;
    }
}
.preview_assignment {
    padding: 12px;
    background-color: @light-1;
    border-radius: 20px;
    border: 2px solid @dark-3;
    .ant-image {
        width: 80%;
        display: block;
        margin: 0 auto;
    }
    img {
        border-radius: 20px;
        margin-bottom: 12px;
    }
    .ant-image-mask {
        border-radius: 20px;
        height: calc(100% - 12px);
    }
}
