.cb_header {
    height: 80px;
    padding: 0px 24px;
    background-color: @white;
    border-bottom: 1.5px solid #F2F1ED;
    .breakpoint-pc({
        height: 104px;
    });
    .ant-typography {
        margin-bottom: 0;
    }

    &__breadcrumb {
        width: 0px;
        overflow: hidden;
    }

    &__menu {
        background: #fff;
        justify-content: center;
        transition: all 0.33s ease;
        cursor: pointer;
        &.--active {
            left: 0;
        }
        &__dropdown {
            font-size: 1.125rem;
            font-weight: 700;
            margin-left: 20px;
            &__icon {
                font-size: 1.125rem !important;
                margin-left: 0.5rem;
            }
        }
    }

    &__icon {
        z-index: 9;
    }

    // apply with screen > 768px
    .breakpoint-tablet({
        display: flex;
        .cb_header__icon {
            display: none;
        }
        .ant-avatar {
            height: 28px;
            width: 28px;
        }
        .cb_header__menu {
            position: relative;
            left: 0;
            height: 100%;
            width: auto;
            background-color: transparent;
            flex-direction: row;
        }
        .cb_header__breadcrumb {
            display: flex;
            width: auto;
        }
    });
}

.popover_profile {
    // width: clamp(350px, 30dvw ,700px);
    text-align: center;
    .ant-typography {
        margin-bottom: 0.5rem;
        width: 100%;
        span:nth-child(2) {
            font-size: 1.125rem;
            font-weight: 600;
        }
    }
    .ant-avatar {
        height: 120px;
        width: 120px;   
        margin-bottom: 16px;
    }

    &__info {
        width: 100%;
    }

    &__logout {
        width: 100%;
    }
}
