@keyframes fadeIn {
    0% {
        transform: translateY(-25%);
    }
    50% {
        transform: translateY(4%);
    }
    65% {
        transform: translateY(-2%);
    }
    80% {
        transform: translateY(2%);
    }
    95% {
        transform: translateY(-1%);
    }
    100% {
        transform: translateY(0%);
    }
}

.popup {
    animation-name: fadeIn;
    animation-duration: 0.4s;
}

@keyframes zoomIn {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

// .ant-btn:hover,
// .ant-menu-item:hover {
//     animation-name: zoomIn;
//     animation-duration: 0.33s;
// }

@keyframes tableFadeInTop {
    0% {
        top: 50px;
    }
    50% {
        top: 0px;
    }
}

@keyframes fadeInLeft {
    0% {
        left: 50px;
    }
    50% {
        left: 0px;
    }
}
@keyframes fadeInRight {
    0% {
        left: -50px;
    }
    50% {
        left: 0px;
    }
}
@keyframes fadeOut {
    0% {
        left: 0px;
        opacity: 1;
    }
    50% {
        left: 50px;
        opacity: 0;
    }
}

@keyframes cardFadeInTop {
    0% {
        top: 50px;
        opacity: 0.5;
    }
    50% {
        top: 0px;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0.5;
    }
    50% {
        top: 0px;
    }
}
@keyframes alert {
    0% {
        background-color: rgba(@error-color, 0.15);
    }
    50% {
        background-color: rgba(@error-color, 0.33);
    }
}

@keyframes fall-1 {
    0% {
        transform: translate(0px, 0px) rotateX(45deg) rotateY(30deg) rotateZ(0deg) scale(0.25);
        opacity: 0;
    }

    5% {
        transform: translate(10px, -10px) rotateX(45deg) rotateY(30deg) rotateZ(0deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(25px, 200px) rotateX(180deg) rotateY(270deg) rotateZ(90deg) scale(1);
        opacity: 0;
    }
}

@keyframes fall-2 {
    0% {
        transform: translate(0px, 0px) rotateX(-20deg) rotateY(10deg) scale(0.25);
        opacity: 0;
    }

    10% {
        transform: translate(-10px, -5px) rotateX(-20deg) rotateY(10deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-10px, 160px) rotateX(-90deg) rotateY(45deg) scale(0.25);
        opacity: 0;
    }
}

@keyframes fall-3 {
    0% {
        transform: translate(0px, 0px) rotateX(0deg) rotateY(45deg) scale(0.5);
        opacity: 0;
    }

    15% {
        transform: translate(7px, 5px) rotateX(0deg) rotateY(45deg) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(20px, 120px) rotateX(-180deg) rotateY(-90deg) scale(0.5);
        opacity: 0;
    }
}

@keyframes focusButton {
    0% {
        top: 0;
        border-width: 2px 2px 4px 2px;
    }
    50% {
        top: 4px;
        border-width: 0px 0px 0px 0px;
    }
}

.ant-table-thead {
    position: relative;
    animation-name: tableFadeInTop;
    animation-duration: 0.7s;
}

.delay-render-list {
    overflow: auto;
    &__items {
        position: relative;
        animation-name: cardFadeInTop;
        animation-duration: 0.5s;
    }
}

.page {
    &__header {
        position: relative;
        animation-name: cardFadeInTop;
        animation-duration: 0.5s;
    }
}

// .cb_dashboard__view {
//     position: relative;
//     animation-name: fadeInRight;
//     animation-duration: 0.5s;
// }

// .ant-tabs-tabpane,
// .cb_dashboard__preview {
//     position: relative;
//     animation-name: fadeInLeft;
//     animation-duration: 0.5s;
// }

// #p_manager .student_assignment.student_assignment__alert {
//     background-color: rgba(@error-color, 0.15);
//     animation: alert 1s infinite;
// }
