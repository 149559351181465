@keyframes blink {
    0% {
        box-shadow: 0 0 20px #e15f40;
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0 0 20px #e15f40;
    }
}

@keyframes fadeIn {
    0% {
        left: 0px;
        opacity: 0.5;
    }
    100% {
        left: 12px;
        opacity: 1;
    }
}

.cb_audio {
    width: 276px;
    position: fixed;
    left: 12px;
    top: 64px;
    background: @white;
    border-radius: 5px;
    padding: 12px;
    box-shadow: 0 0 20px rgba(@sub-color, 0.33);
    animation-name: fadeIn;
    animation-duration: 0.5s;
    z-index: 9999;
    transform: scale(0.5);
    transition: 0.3s;
    transform-origin: top left;
    opacity: 0.5;

    &:hover {
        transform: scale(1);
        opacity: 1;
    }

    audio {
        width: 100%;
        border-radius: 0;
    }

    &__controls {
        margin-bottom: 12px;
    }

    &__list {
        width: 100%;
        margin-top: 12px;
        &__item {
            height: 40px;
            line-height: 20px;
            border-radius: 5px;
            padding: 10px 8px;
            cursor: pointer;
            margin-bottom: 4px;
            background-color: transparent;
            transition: all 0.33s;
            &.--playing,
            &:hover {
                background-color: rgba(@success-color, 0.33);
            }
            img {
                width: 15px;
                float: right;
            }
        }
    }

    .ant-typography {
        width: 100%;
        display: block;

        .ant-btn {
            float: right;
        }
    }

    .audio_image {
        width: 100px;
        margin: 12px;
        border-radius: 999px;
        -webkit-animation: blink 1.5s linear infinite;
        -moz-animation: blink 1.5s linear infinite;
        -ms-animation: blink 1.5s linear infinite;
        -o-animation: blink 1.5s linear infinite;
        animation: blink 1.5s linear infinite;
    }
}

.cb_video {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(@sub-color, 0.66);
    padding: 100px;
    video {
        max-width: 95vw;
        max-height: 95vh;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 !important;
    }
}
