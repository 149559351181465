/*
    ********************************************************************************
    ********************************************************************************

               WARNING : This is a common class, please do not modify it!

    ********************************************************************************
    ********************************************************************************
*/
/* justify-content: center */
/* justify-content: start */
/* justify-content-between */
/* justify-content: around */
/* justify-content: end */
/* 
  Created by Monochrome
*/
.flex__center__center {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.flex__center__start {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}
.flex__center__end {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}
.flex__center__stretch {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}
.flex__start__start {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-content: flex-start;
}
.flex__start__center {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.flex__start__end {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}
.flex__start__stretch {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}
.flex__between__start {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}
.flex__between__center {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.flex__between__end {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}
.flex__between__stretch {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}
.flex__around__center {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.flex__evenly__center {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-evenly;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.flex__around__start {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}
.flex__around__end {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}
.flex__around__stretch {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}
.flex__end__center {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
.flex__end__start {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}
.flex__end__end {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}
.flex__end__stretch {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}
.flex__column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex__column__center {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.flex__wrap {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
.flex__wrap__reverse {
  flex-wrap: reverse;
  -ms-flex-wrap: wrap-reverse;
}
.flex__inline {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.gap {
  gap: 1rem;
  grid-gap: 1rem;
}
.gap-sm {
  gap: 0.5rem;
  grid-gap: 0.5rem;
}
/* 
  Created by Monochrome
*/