.book_item {
    cursor: pointer;
    margin-bottom: 1rem;
    .ant-image {
        transition: all 0.33s ease;
        border-radius: 12px;
        box-shadow: 0px 0px 12px 3px transparent;
        margin-bottom: 8px;
        img {
            border-radius: 12px;
            object-fit: fill;
            aspect-ratio: 3 / 4;
            border: 1px solid @dark-3;
        }
    }
    b {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* Limit to 2 lines */
        overflow: hidden;
    }
    &:hover {
        opacity: 1;
        .ant-image {
            box-shadow: 0px 0px 12px 3px @primary;
        }
    }

    &.--selected {
        position: relative;
        img {
            border: 3px solid @success-color;
        }
        &::after {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            border-radius: 999px;
            background-color: @success-color;
            border: 2px solid @white;
            box-shadow: 0px 0px 0px 2px @success-color;
            top: 12px;
            left: 20px;
        }
    }
    &.--unselected {
        opacity: 0.5;
    }
}
.book_reading {
    background-color: @primary-light-2;
    padding: 24px !important;
    border-radius: 24px;
    height: fit-content;

    .book_description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* Limit to 3 lines */
        overflow: hidden; /* Hide overflow content */
    }
}

.cb_dashboard {
    .ant-segmented {
        margin-bottom: 24px;
    }

    .card__report {
        img {
            max-width: 25%;
            height: auto;
        }
    }

    .current_book {
        .ant-btn {
            width: 100%;
            margin-top: 1rem;
            .breakpoint-sm ({
                    width: auto;
                    margin-top: 0;
                });;
        }
    }

    .card.card__report {
        background-color: @primary-light-1;
        border-color: @primary-light-3;
    }
}
.teacher_report {
    .report-section {
        padding: 24px;
        height: 100%;
        img {
            display: none;
        }
        // h5 {
        //     margin-bottom: 1rem;
        // }
        .ant-typography {
            color: #403e3c;
        }

        .breakpoint-sm({
            img {
                display: block;
            }
        });
        .ant-input.ant-input-borderless {
        }
        // .ant-input.ant-input-borderless:hover,
        .ant-input.ant-input-borderless:focus {
            background-color: @white;
        }
    }

    .report-content {
        background-color: #faebff;
        height: 100%;
        img {
            height: 80px;
            width: auto;
        }
    }

    .report-status {
        background-color: @primary-light-1;
        img {
            height: 40px;
            width: auto;
        }
    }

    .report-homework {
        background-color: @info-color-light;
        background-image: url(/assets/images/card-normal.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 30%;
    }

    .report-commend {
        background-color: @success-color-light;
        border-color: @success-color-light-1;
        img {
            height: 80px;
            width: auto;
        }
    }

    .report-comment {
        background-color: @primary-light-1;
        border-color: @primary-light-3;
    }

    .report-criticize {
        background-color: @danger-color-light-1;
        border-color: @danger-color-light-2;
        img {
            height: 80px;
            width: auto;
        }
    }

    .report-mentions {
        position: relative;
        width: 100%;
        .ant-mentions {
            border: 0;
            background-color: transparent;
            textarea {
                background-color: transparent;
                padding: 0;
                &:focus {
                    outline: none;
                    background-color: @white;
                }
            }
        }
        .btn__suggest {
            position: absolute;
            right: 0;
            top: -35px;
        }
    }

    .suggest-table {
        .ant-table-tbody > tr > td {
            border-radius: 12px;
        }
        .ant-table-tbody > tr > td:hover {
            background-color: @primary-light-3;
            cursor: pointer;
        }
    }

    .ant-select {
        width: 60%;
        .ant-select-selection-item {
            color: @dark;
            font-weight: 700;
        }
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: @dark;
        .ant-select-selection-item {
            font-weight: 700;
        }
    }

    .ant-mentions-disabled > textarea {
        color: @dark;
    }
}
