.breakpoint-xs(@rules) {
    @media screen and (min-width: 320px) {
        @rules();
    }
}
.breakpoint-sm(@rules) {
    @media screen and (min-width: 768px) {
        @rules();
    }
}
.breakpoint-md(@rules) {
    @media screen and (min-width: 720px) {
        @rules();
    }
}
.breakpoint-lg(@rules) {
    @media screen and (min-width: 1280px) {
        @rules();
    }
}
.breakpoint-xl(@rules) {
    @media screen and (min-width: 1440px) {
        @rules();
    }
}

.breakpoint-tablet(@rules) {
    @media screen and (min-width: 768px) {
        @rules();
    }
}
.breakpoint-laptop(@rules) {
    @media screen and (min-width: 1024px) {
        @rules();
    }
}
.breakpoint-pc(@rules) {
    @media screen and (min-width: 1440px) {
        @rules();
    }
}
