.ant-input {
    &.--border-dark {
        border-color: @dark-4;
    }
}

.ant-input[disabled] {
    color: @dark;
    font-weight: 600;
}

.ant-input-affix-wrapper {
    .ant-input-prefix {
        margin: auto 8px auto 4px;
    }
    &.--border-dark {
        border-color: @dark-4;
    }
}
.ant-input-group.ant-input-group-compact {
    display: flex;
}
.ant-input-affix-wrapper-lg.ant-input-md {
    padding: 10px 11px;
}
.ant-radio-group.ant-radio-group-outline.ant-radio-group-large {
    transform: scale(1.2);
    transform-origin: left;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px 16px;
    height: 56px;
    line-height: 56px;
}

.ant-form-item-label > label {
    font-weight: 700;
}

.ant-form-item-explain-error {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    // padding-top: 5px;
}

.ant-select-lg {
    color: @dark;
    .ant-select-arrow {
        font-size: 1rem;
        color: @dark;
    }
}

.ant-layout-content {
    position: relative;
}

.ant-popover-arrow {
    display: none;
}

.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 1;
    // border-width: 1px 1px 3px 1px;
    transition: all 0.25s ease;
    &.--md {
        height: 48px;
    }
    &.--border-dark {
        border-color: @dark-1;
    }

    &.btn-white {
        border-width: 1.5px 1.5px 3px 1.5px;
        background-color: @white;
        border-color: @dark-3;
        &:hover,
        &:focus {
            background-color: @white;
            border-color: @primary;
        }
        &:active {
            border-width: 1px;
        }
    }

    &.ant-btn-lg {
        &.ant-btn-primary {
            border-width: 0px 0px 4px 0px;
        }
        &.btn-white {
            border-width: 2px 2px 4px 2px;
        }
    }

    &.ant-btn-primary {
        border-width: 0px 0px 3px 0px;
        background-color: @primary;
        &.btn-white {
            background-color: @white;
        }
        &:hover,
        &:focus {
            background-color: @primary;
        }
        &.ant-btn-dangerous {
            background-color: @error-color;
        }
        &.btn-info {
            background-color: #3b8aff;
            border: 0px;
            color: @white;
        }
        &.btn-success {
            background-color: @success-color;
            border: 0px;
            color: @white;
        }
        &.btn-test {
            background-color: @test-color;
            color: @white;
            border: 0px;
        }
    }

    &.ant-btn-dangerous {
        &:hover {
            border-color: @error-color;
        }
    }

    &:not(.ant-btn-text, .ant-btn-link, .un-transition):active {
        border-width: 0px;
        transform: translateY(4px);
        // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
        border-color: @primary;
    }
}
.ant-btn-dangerous.ant-btn-primary {
    border-color: #dd0000;
}

// Table
.ant-table {
    .ant-table-title {
        // border-radius: 20px 20px 0 0;
        .ant-input-affix-wrapper-lg {
            width: 33%;
            border-width: 1px;
        }
    }
    .ant-table-thead > tr > th {
        font-weight: 700;
        white-space: nowrap;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid @light-1;
        padding: 11px 14px;
        font-weight: 600;
        white-space: nowrap;
    }
    //sorter
    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
        font-size: 13px;
    }

    .ant-table-filter-trigger {
        font-size: 16px;
    }

    .ant-checkbox-inner {
        width: 18px;
        height: 18px;
    }
}
.ant-table-wrapper {
    background-color: @white;
    border: 1px solid @light-1;
    border-radius: 20px;
    width: 100%;
    overflow: auto;
    .ant-table-pagination.ant-pagination {
        background-color: @white;
    }
}

// Tag
.ant-tag-has-color {
    color: @dark;
    font-weight: 700;
}

// Pagination
.ant-pagination {
    .ant-pagination-item {
        border: 0;
        margin-right: 0;
        border-radius: 999px;
    }
    .ant-pagination-item-active {
        background-color: @primary-light-1;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        border: 0;
        color: @dark;
    }

    .ant-pagination-prev,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        margin-right: 0;
    }

    .ant-pagination-options {
        display: none;
    }
}

// Tabs
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    display: none;
}
.ant-tabs {
    & > .ant-tabs-nav .ant-tabs-nav-list {
        background-color: #f2f1ed;
        border-radius: 12px;
        padding: 2px;
    }

    .ant-tabs-tab {
        padding: 2px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0px;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
        color: @dark;
        font-weight: 700;
        border-width: 2px 2px 4px 2px;
        border-style: solid;
        border-radius: 12px;
        border-color: transparent;
        padding: 7px 16px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        border-color: #d9d7d2;
        background-color: #fff;
    }

    .ant-tabs-ink-bar {
        height: 100% !important;
        display: none;
    }
}

// Drawer
.ant-drawer {
    .ant-drawer-content {
        .ant-drawer-header {
            padding: 24px;
            border: none;
            .ant-drawer-title {
                font-size: 28px;
                font-weight: 800;
            }
            .ant-drawer-close {
                font-size: 20px;
                position: absolute;
                right: 24px;
            }
        }
        .ant-drawer-body {
            padding: 0px 24px;
        }

        .ant-drawer-footer {
            padding: 22px;
        }
    }
    &.ant-drawer--large {
        .ant-drawer-content {
            .ant-drawer-header {
                padding: 32px 40px;

                .ant-drawer-close {
                    font-size: 24px;
                    margin-right: 1rem;
                }
            }
            .ant-drawer-body {
                padding: 24px 40px;
            }

            .ant-drawer-footer {
                padding: 22px 40px;
            }
        }
    }
}

// Typo
.ant-typography {
    font-weight: 700;
    &.--mb-1 {
        margin-bottom: 1rem;
        display: block;
    }
    &.--dotted {
        padding-left: 12px;
        position: relative;
        &::after {
            position: absolute;
            content: "";
            height: 6px;
            width: 6px;
            background-color: @dark;
            border-radius: 6px;
            left: 0px;
            top: 8px;
        }
    }
    &.text--left {
        text-align: left;
        width: 100%;
        display: block;
    }
    &.--nowrap {
        white-space: nowrap;
    }
}
.ant-typography strong {
    font-weight: 700;
}

.ant-typography.ant-typography-warning {
    color: @warning-bold;
}

// Modal
.ant-modal:not(.ant-modal-confirm) {
    .ant-modal-content {
        .ant-modal-header {
            border: 0;
            padding: 24px;
            .ant-modal-title {
                font-size: 20px;
                font-weight: 700;
            }
        }
        .ant-modal-close-x {
            width: 70px;
            height: 70px;
            font-size: 22px;
            line-height: 70px;
        }
        .ant-modal-body {
            max-height: calc(100vh - 100px);
            overflow: auto;
            padding: 12px 24px;
        }
        .ant-modal-footer {
            border: none;
            .ant-btn {
                height: 50px;
                padding: 8px 18px;
            }
        }
        .ant-steps {
            width: 30%;
        }
    }
    &.modal_pdf {
        .ant-modal-body {
            padding: 18px 0 18px 18px;
            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }
}
.ant-modal.ant-modal-confirm {
    .ant-modal-confirm-title {
        font-size: 20px;
        margin-top: 0.5rem;
        font-weight: 700;
    }
    .ant-modal-body {
        padding: 24px;
    }
    .ant-modal-confirm-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        .ant-modal-confirm-content {
            margin: 0;
            width: 100%;
        }
        .ant-input-group.ant-input-group-compact {
            margin-top: 0.5rem;
        }
    }
    .ant-modal-confirm-btns {
        .ant-btn {
            height: 48px;
        }
    }

    &.--centered {
        .ant-modal-confirm-body {
            text-align: center;
            .ant-modal-confirm-title {
                display: block;
                width: 100%;
                text-align: center;
            }
            .anticon {
                margin: 0 auto;
            }
        }
        .ant-modal-confirm-btns {
            text-align: center;
        }
    }

    &.exercise-result {
        .ant-modal-content {
            border-radius: 24px;
            border: 4px solid @primary-light-2;
        }
        .ant-modal-body {
            z-index: 1;
            position: relative;
            background: @white;
            border-radius: 20px;
        }
        &.low-score .ant-modal-content::before,
        &.medium-score .ant-modal-content::before,
        &.high-score .ant-modal-content::before {
            content: "";
            display: block;
            width: 250px;
            height: 250px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 0;
            position: absolute;
            top: -50%;
            left: 50%;
            transform: translate(-50%, 20%);
        }

        &.low-score .ant-modal-content::before {
            background-image: url("/assets/images/bad.png");
        }

        &.medium-score .ant-modal-content::before {
            background-image: url("/assets/images/medium.png");
        }

        &.high-score .ant-modal-content::before {
            background-image: url("/assets/images/good.png");
        }

        .ant-modal-confirm-title {
            font-size: 24px;
        }
        .assignment-result__mark {
            font-size: 32px;
            color: @warning-bold;
            font-weight: 800;
            margin: 12px auto;
            small {
                font-size: 18px;
                color: #b2b0ab;
            }
        }
        .ant-rate {
            font-size: 50px;
            margin: 0 auto;
            .ant-rate-star:not(:last-child) {
                margin-right: 2rem;
            }
            li:nth-child(2) {
                font-size: 60px;
            }
        }
        .ant-modal-confirm-btns {
            .ant-btn {
                width: 100%;
            }
        }
    }

    .ant-modal-close {
        right: 12px;
        top: 12px;
        .ant-modal-close-x {
            font-size: 20px;
        }
    }
}

// Steps
.ant-steps-item-icon .ant-steps-icon {
    top: -2.5px;
}
.ant-steps-item-container {
    margin-bottom: 30px;
}

// Popover
.ant-popover {
    &:not(.tick_cross) {
        width: clamp(400px, 40dvw, 500px);
    }
    &.list_exercise {
        width: auto;
        .ant-typography {
            width: 100%;
            gap: 40px;
        }
    }
    &-content {
        border-radius: 20px;
        border: 2px solid @light-1;
    }
    &-title {
        padding: 16px 16px 8px 16px;
        border: 0;

        .ant-typography {
            font-weight: 700;
            font-size: 20px;
        }
        .ant-btn {
            text-transform: uppercase;
            padding: 0;
        }
    }
    &.popover_noti {
        .ant-popover-inner-content {
            padding: 0;
        }
    }
    &.tick_cross {
        .ant-popover-buttons {
            display: inline-flex;
            flex-direction: row-reverse;
            gap: 1rem;
        }
    }
}

// ant-segmented
.ant-segmented {
    font-size: 18px;
    font-weight: 700;
    padding: 4px;

    .ant-segmented-item {
        border: 2px solid transparent;
        &.ant-segmented-item-selected {
            border: 2px solid @dark-3;
            border-radius: 12px;
        }
    }

    &.ant-segmented-lg .ant-segmented-item-label {
        padding: 0 16px;
    }
}

// Tree
.ant-tree {
    font-size: 18px;
    font-weight: 600;
    .ant-tree-list-holder-inner {
        border: 2px solid @dark-3;
        border-radius: 20px;
        padding: 16px;
    }
    &.ant-tree-directory .ant-tree-treenode::before {
        bottom: 0;
        border-radius: 12px;
    }
    .ant-tree-treenode {
        padding: 4px;
        // &.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last {
        //     padding-bottom: 12px;
        //     margin-bottom: 12px;
        //     border-bottom: 2px solid @light-1;
        // }
        // &.ant-tree-treenode-selected,
        // &.ant-tree-treenode-checkbox-indeterminate {
        //     padding-bottom: 8px;
        //     margin-bottom: 8px;
        //     border-bottom: 2px solid @light-1;
        // }
    }
    .ant-tree-checkbox {
        margin-top: 0px;

        &:hover .ant-tree-checkbox-inner {
            border-color: @success-color;
        }
    }
    .ant-tree-switcher {
        display: none;
    }
    .ant-tree-checkbox-inner {
        height: 28px;
        width: 28px;
        border-radius: 8px;
        &::after {
            width: 8px;
            height: 16px;
            top: 40%;
        }
    }
    .ant-tree-node-content-wrapper {
        line-height: 30px;
    }

    .ant-tree-checkbox-checked::after {
        border: 0;
    }

    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: @success-color;
        border-color: @success-color;
    }

    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: @success-color;
        width: 16px;
        top: 50%;
        border-radius: 3px;
    }
    &.ant-tree-directory .ant-tree-treenode-selected::before {
        display: none;
    }
    &.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: @dark;
    }
}

.ant-notification-notice {
    color: @white;
    font-weight: 600;
    .ant-notification-notice-icon,
    .ant-notification-notice-message,
    .anticon-close {
        color: @white;
    }
    &.ant-notification-notice-warning {
        background-color: #f3a000;
    }
    &.ant-notification-notice-success {
        background-color: @success-color;
    }
    &.ant-notification-notice-error {
        background-color: @error-color;
    }
}
.fixed-picker {
    .ant-picker-footer {
        height: 45px;
    }
}

// .ant-dropdown
.ant-dropdown {
    .ant-dropdown-menu {
        padding: 8px;
        .ant-dropdown-menu-item {
            border-radius: 8px;
            padding: 8px 12px;
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 18px;
        }
    }
}

.ant-image-preview-img-wrapper {
    img {
        width: auto;
    }
}
