// Define variables for font sizes
@font-base-mobile: 14px;
@font-base-desktop: 16px;

:root {
    font-weight: 600;
    min-height: 100%;
    font-optical-sizing: auto;
    font-style: normal;
}
* {
    margin: 0px;
    padding: 0px;
    font: inherit;
}

// Apply base font-size to the html element
html {
    font-size: @font-base-mobile;
}

// Use media queries for larger screens
@media (min-width: 768px) {
    html {
        font-size: @font-base-desktop;
    }
}

html,
body {
    height: 100%;
    width: 100vw;
    overflow: hidden;
}

body {
    font-size: 1rem;
}

::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.breakpoint-sm({
    /* width */
    ::-webkit-scrollbar {
        width: 16px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border: 4px solid @white;
        background-clip: border-box;
        border-radius: 9999px;
        background-color: #e5e3df;
        transition: all 0.33s;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #b2b0ab;
    }

});

p {
    margin: 0;
    font-weight: 600;
}
