.cb_book {
    height: 100dvh;
    // background: rgba(@primary-color, 0.05);
    &:focus-visible {
        outline: 0;
    }

    .ant-layout-sider {
        padding: 24px;
        background-color: @white;
        border-right: 1px solid @dark-2;
        overflow: auto;
        .ant-collapse-borderless {
            background-color: transparent;
            margin-top: 1rem;
            font-weight: 700;

            > .ant-collapse-item {
                border-bottom: 0;
                > .ant-collapse-header {
                    padding: 10px 30px 10px 14px;
                }
            }

            .ant-collapse-expand-icon {
                position: absolute;
                right: 0;
                .anticon {
                    font-size: 16px;
                }
            }

            .ant-collapse-content > .ant-collapse-content-box {
                .ant-typography {
                    display: block;
                    height: 40px;
                    padding-left: 12px;
                    line-height: 40px;
                    border-radius: 8px;
                    transition: all 0.33s ease;
                    cursor: pointer;
                    &:hover {
                        background-color: @primary-light-2;
                    }
                }
            }
        }
    }
    .book_sidebar {
        &.ant-layout-sider-collapsed {
            width: 0px !important;
            min-width: 0px !important;
            max-width: 0px !important;
            padding: 0;
            overflow: hidden;
            .ant-layout-sider-trigger {
                left: 18px;
            }
        }
        .ant-layout-sider-trigger {
            height: 40px;
            width: 40px !important;
            position: fixed;
            left: clamp(280px, 22dvw, 350px);
            bottom: 24px;
            background-color: @white;
            color: @dark;
            border-radius: 12px;
            border: 1px solid @dark-3;
            border-width: 1px 1px 4px 1px;
            line-height: 40px;
            .breakpoint-lg({
                bottom: 40px;
            });
        }
    }

    .give_exam {
        position: fixed;
        bottom: 24px;
        left: clamp(385px, calc(20vw + 24px), 370px);
    }
}

// .cb_header-book,
// .cb_footer-book {
//     position: fixed;
//     right: 0;
//     height: 100px;
//     padding: 0 1rem;
//     width: 100%;
//     z-index: 2;
// }

.cb_header-book {
    z-index: 0;
    height: 80px;
    position: relative;
    background-color: @white;
    padding: 0px 24px;
    .ant-typography {
        line-height: 50px;
        height: 50px;
    }
    .breakpoint-pc({
        height: 104px;
    });

    &__tools {
        display: none;
        .breakpoint-lg ({
            display: flex;
        });;
    }
}

.cb_footer-book {
    text-align: center;
    padding: 0 18px 24px 18px;
    background-color: @dark-2;
    .breakpoint-lg({ 
        padding: 0 24px 40px 24px;
    });
    // &:hover {
    //     opacity: 1;
    // }
    &__paging {
        line-height: 1;
        .ant-input {
            text-align: center;
            width: 80px;
            background-color: transparent;
            padding: 6px 0px;
            color: @dark;
            background-color: @white;
            &::placeholder {
                color: @dark;
                transition: all 0.33s ease;
            }
        }
    }
}

.cb_pageview {
    width: auto;
    height: auto;
    text-align: center;
    overflow: auto;
    position: relative;
    padding: 24px 24px 12px 24px;
    background: @dark-2;
    white-space: nowrap;
    .breakpoint-tablet({
        height: calc(100dvh - 210px);
        padding: 40px 40px 24px 40px;
    });
    .page_items {
        position: relative;
        height: 100%;
        text-align: left;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &__select {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            opacity: 0;
            z-index: 1;
        }
        &:hover {
            .page_items__select {
                opacity: 0.8;
            }
        }
        .breakpoint-tablet({
            img {
                height: 100%;
                width: auto;
                pointer-events: none;
                user-select: none;
            }
        });
        .breakpoint-laptop({
            display: inline-block;
        });
        &.--scale-2x {
            height: 120%;
        }
        &.--scale-3x {
            height: 140%;
        }
        &.--scale-4x {
            height: 160%;
        }
        &.--scale-5x {
            height: 180%;
        }
        .cb_exercise {
            position: absolute;
            background-color: rgba(@primary, 0);
            transition: all 0.33s ease;
            cursor: pointer;
            border-radius: 12px;
            .ant-btn {
                position: relative;
                opacity: 0;
                top: 10px;
                transition: all 0.33s ease;
            }
            &.--selected {
                border: 2px solid @primary;
                background-color: rgba(@primary, 0.15);
            }
            &:hover {
                background-color: rgba(@primary, 0.25);
                .ant-btn {
                    opacity: 1;
                    top: 0;
                }
            }
        }
        .ant-skeleton {
            position: absolute;
            height: 100%;
            width: 100%;
            .ant-skeleton-image {
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
    }

    .page_items:nth-child(1) {
        text-align: right;
    }

    &::-webkit-scrollbar-thumb {
        border-color: @dark-2;
    }
}
